import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { tkHost } from '@settings';
import React, { FC } from 'react';
import { trackSavedVendorListClick } from '../../contexts/WeddingsContext/analytics';
import Styles from './styles.scss';

export interface ShowAlertProps {
	isFailure: boolean;
	vendor: Vendor.Raw;
}

const ShowAlert: FC<ShowAlertProps> = (props) => {
	const { vendor, isFailure } = props;

	const { track } = useAnalyticsContext();

	const savedCategoryUrl = vendor.categoryInfo?.plural?.slug;

	const trackListClick = () => {
		trackSavedVendorListClick(track);
	};

	if (isFailure) {
		return <div>There was an issue saving this vendor. Please try again.</div>;
	}

	return (
		<span role="alert">
			Added to&nbsp;
			<a
				className={Styles.favLink}
				href={`${tkHost}/wedding-vendor-manager/saved/${savedCategoryUrl}`}
				target="_blank"
				rel="noopener noreferrer nofollow"
				onClick={trackListClick}
			>
				Your Vendors
			</a>
		</span>
	);
};

export default ShowAlert;
