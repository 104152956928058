import { useAppSelector } from '@redux/hooks';
import React, { type FC, useState } from 'react';
import type { DropdownProps } from './DropdownProps';
import DesktopDropdown from './containers/Desktop';
import MobileDropdown from './containers/Mobile';

export interface DropdownContainerProps extends DropdownProps {
	modalHeader: string;
	isModalOpen: boolean;
	onClose: () => void;
	selectedOptions?: string[];
}

const DropdownContainer: FC<DropdownContainerProps> = (props) => {
	const {
		modalHeader,
		isModalOpen,
		onClose,
		selectedOptions,
		...dropdownProps
	} = props;
	const [stagedOptions, setStagedOptions] = useState(selectedOptions || []);
	const isDesktop = useAppSelector((state) => state.viewport.greaterThan.small);

	return isDesktop ? (
		<DesktopDropdown
			modalHeader={modalHeader}
			stagedOptions={stagedOptions}
			setStagedOptions={setStagedOptions}
			{...dropdownProps}
		/>
	) : (
		<MobileDropdown
			isModalOpen={isModalOpen}
			modalHeader={modalHeader}
			onClose={onClose}
			stagedOptions={stagedOptions}
			setStagedOptions={setStagedOptions}
			{...dropdownProps}
		/>
	);
};

export default DropdownContainer;
