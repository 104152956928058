import { Caption } from '@xo-union/tk-ui-typography';
import { ScreenReaderOnly } from '@xo-union/ui-accessibility';
import React, { useContext, useEffect, useRef, useState, FC } from 'react';
import { propsContext } from '../../context';
import { useClassName } from '../../hooks';
import ServiceArea from './ServiceArea';
import State from './state';
import { getCityState, truncateServiceArea } from './utilities';

const VendorLocation: FC = () => {
	const { serviceArea, vendorLocation } = useContext(propsContext);
	const locationTextClassName = useClassName('location-text');
	const locationClassName = useClassName('location');
	const containerRef = useRef<HTMLDivElement>(null);
	const captionRef = useRef<HTMLDivElement>(null);
	const [truncatedServiceArea, setTruncatedServiceArea] = useState(serviceArea);

	useEffect(() => {
		if (serviceArea) {
			setTruncatedServiceArea(
				truncateServiceArea(containerRef, captionRef, serviceArea),
			);
		}
	}, [containerRef.current, captionRef.current]);

	const [city, state] = getCityState(vendorLocation);
	const serviceAreaExists = serviceArea && truncatedServiceArea?.length > 0;

	return (
		<div
			className={locationClassName}
			ref={containerRef}
			data-testid="location-container"
		>
			<ScreenReaderOnly>Location:</ScreenReaderOnly>
			<Caption className={locationTextClassName} ref={captionRef}>
				<ServiceArea
					serviceArea={serviceArea}
					truncatedServiceArea={truncatedServiceArea}
				/>
				{!serviceAreaExists && (
					<span>
						{city}
						<State state={state} />
					</span>
				)}
			</Caption>
		</div>
	);
};

export default VendorLocation;
