import React, { forwardRef } from 'react';
import VendorCardBase from '../../components/VendorCardBase';
import { PropsContextProvider } from '../../context/propsContext';
import type { VendorCardLargeProps } from '../../types';

const VendorCardLarge = forwardRef<HTMLDivElement, VendorCardLargeProps>(
	(props, ref) => (
		<PropsContextProvider ref={ref} {...props} size="lg">
			<VendorCardBase />
		</PropsContextProvider>
	),
);

export default React.memo(VendorCardLarge);
