import Icon from '@xo-union/tk-component-icons';
import React, { type KeyboardEvent, type MouseEvent, type FC } from 'react';
import vendorCardStyles from '../../../css/vendorCard.scss';
import styles from './styles.scss';

export interface SliderButtonProps {
	direction?: 'left' | 'right';
	onClick(
		event: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>,
	): void;
	disabled: boolean;
	className: string;
}

const SliderButton: FC<SliderButtonProps> = (props) => {
	const { direction, disabled } = props;

	const onClick = (
		event: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>,
	) => {
		event.stopPropagation();
		props.onClick(event);
	};

	const wrapperClassName =
		direction === 'left'
			? vendorCardStyles.prevButtonWrapper
			: vendorCardStyles.nextButtonWrapper;
	const caretClassName =
		direction === 'left' ? styles.prevButtonCaret : styles.nextButtonCaret;
	const caretName = direction === 'left' ? 'caret_left' : 'caret_right';
	const label = direction === 'left' ? 'Previous' : 'Next';

	const onClickWrapper = (
		event: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>,
	) => {
		event.stopPropagation();
	};

	const className = disabled
		? styles.sliderButtonDisabled
		: styles.sliderButton;

	return (
		<div
			tabIndex={0}
			role="menuitem"
			onClick={onClickWrapper}
			className={wrapperClassName}
			onKeyPress={onClickWrapper}
			data-testid="container"
		>
			<div
				role="button"
				aria-label={label}
				tabIndex={0}
				className={className}
				onClick={onClick}
				onKeyPress={onClick}
			>
				<Icon name={caretName} className={caretClassName} size="md" />
			</div>
		</div>
	);
};

export default SliderButton;
