import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { StateCodes } from '@typings/states';
import { Img, Picture } from '@xo-union/tk-component-picture';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { getPageType } from '../../../redux/page';
import {
	CATEGORIES_LIST_VIEW,
	CATEGORY_RESULTS_PAGE,
	CITY_LEVEL,
	CLICK_THROUGH_TO_VENDOR_CATEGORY,
	MARKETPLACE_HOME_PAGE,
} from '../../../utils/tracking';
import Styles from './styles.scss';
import type { TrackingProperties } from './types';
import { getCardTitle, getLinkUrl } from './utils';

const CARD_IMG_WIDTH = 93;
const CARD_IMG_HEIGHT = 76;

export interface SmallCategoryCardProps {
	categoryCode: Category.CategoryCode;
	categoryName: string;
	categorySlug: string;
	city?: string;
	mediaApiId: string;
	pageType: Page.PageType;
	sortType: Search.SortType;
	stateCode?: StateCodes;
}

const SmallCategoryCard: FC<SmallCategoryCardProps> = ({
	categoryCode,
	categoryName,
	categorySlug,
	city,
	mediaApiId,
	pageType,
	sortType,
	stateCode,
}) => {
	const { track } = useAnalyticsContext();

	const handleClick = () => {
		let properties: TrackingProperties = {
			sourcePage: MARKETPLACE_HOME_PAGE,
			sourceContent: CATEGORIES_LIST_VIEW,
			vendorCategoryCode: categoryCode,
		};

		if (pageType === CITY_LEVEL) {
			properties = {
				sourceContent: 'vendor default results',
				sourcePage: CATEGORY_RESULTS_PAGE,
				selection: categoryName,
				location: `${city || null}, ${stateCode || null}`,
			};
		}

		track({
			event: CLICK_THROUGH_TO_VENDOR_CATEGORY,
			properties,
		});
	};

	return (
		<div className={Styles.container}>
			<a
				className={Styles.categoryLink}
				href={getLinkUrl(categorySlug, pageType, sortType, city, stateCode)}
				onClick={handleClick}
			>
				<div className={Styles.imageContainer}>
					<Picture env="production" id={mediaApiId}>
						<Img
							alt=""
							elementProps={{
								'aria-hidden': true,
								// eslint-disable-next-line @typescript-eslint/ban-ts-comment
								// @ts-ignore
								'data-testid': 'small-category-card-img',
								loading: 'lazy',
							}}
							className={Styles.image}
							height={CARD_IMG_HEIGHT}
							width={CARD_IMG_WIDTH}
							smartCrop
						/>
					</Picture>
				</div>
				<div className={Styles.infoContainer}>
					<div className={Styles.categoryName}>
						{getCardTitle(pageType, categoryName, city, stateCode)}
					</div>
				</div>
			</a>
		</div>
	);
};

const mapStateToProps = (state: Redux.State) => ({
	pageType: getPageType(state),
	sortType: state.search.sort,
});

export default connect(mapStateToProps)(SmallCategoryCard);
