import classnames from 'classnames';
import React, { useContext, FC } from 'react';
import MediaNotAvailable from '../../../../media_not_available';
import { propsContext } from '../../../context';
import { useClassName, useHandleEvent } from '../../../hooks';

const DefaultImage: FC = () => {
	const { url } = useContext(propsContext);
	const [handleEvent] = useHandleEvent();
	const imageClassName = useClassName('image-container');
	const defaultImageLinkClass = useClassName('image-default-link');

	const defaultImageClassNames = classnames(
		imageClassName,
		defaultImageLinkClass,
	);

	const onClick = () => {
		handleEvent({
			name: 'card',
			type: 'click',
		});
	};

	return (
		// eslint-disable-next-line react/jsx-no-target-blank
		<a
			href={url}
			onClick={onClick}
			rel="noopener"
			target="_blank"
			className={defaultImageClassNames}
		>
			<MediaNotAvailable />
		</a>
	);
};

export default DefaultImage;
