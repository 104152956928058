import { isNewBadgeAssignmentSelector } from '@redux/experiments/selectors/is-new-badge';
import { useAppSelector } from '@redux/hooks';
import { Caption } from '@xo-union/tk-ui-typography';
import React, { useContext, type FC } from 'react';
import { propsContext } from '../../context';
import { useClassName } from '../../hooks';
import ReviewCount from './reviewCount';
import ReviewScore from './reviewScore';
import ReviewStar from './reviewStar';

const Reviews: FC = () => {
	const { reviewCount, isNew } = useContext(propsContext);
	const reviewContainerClassName = useClassName('review-container');
	const reviewBadgeNewClassName = useClassName('review-badge-new');
	const isNewBadgeEnabled = useAppSelector(
		(state) => isNewBadgeAssignmentSelector(state) === 'variant1',
	);
	const showNewBadge = isNewBadgeEnabled && isNew && reviewCount === 0;

	return (
		<Caption role="complementary" className={reviewContainerClassName}>
			<ReviewStar showNewBadge={showNewBadge} />
			{!showNewBadge && <ReviewScore />}
			{!showNewBadge && <ReviewCount />}
			{showNewBadge && <span className={reviewBadgeNewClassName}>New</span>}
		</Caption>
	);
};

export default Reviews;
