import styles from '../../../css/vendorCard.scss';
import type { Size } from '../../../types';

export const content = (size?: Size) => {
	switch (size) {
		case 'lg':
			return styles.contentLarge;
		case 'md':
			return styles.contentMedium;
		case 'sm':
			return styles.contentSmall;
		default:
			return styles.contentLarge;
	}
};

export const imageContainer = (size?: Size) => {
	switch (size) {
		case 'sm':
			return styles.imageContainerSmall;
		default:
			return styles.imageContainer;
	}
};
