import { useShouldShowPricingFilter } from '@feature-flags/hooks/VendorsSearch/use-should-show-pricing-filter';
import { Body2 } from '@xo-union/tk-ui-typography';
import React, { useContext, type FC } from 'react';
import { propsContext } from '../../context';
import { useClassName } from '../../hooks';
import Item from './item';

const VIDEOGRAPHY_TEXT = 'Videographer services';

const formatCurrency = (value: number) => {
	return new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		notation: 'standard',
		compactDisplay: 'long',
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
		maximumSignificantDigits: Math.floor(Math.log10(value)) + 1,
	}).format(value);
};

const SecondaryInfo: FC = () => {
	const shouldShowPricing = useShouldShowPricingFilter();
	const secondaryInfoContainerClassName = useClassName(
		'secondary-info-container',
	);
	const startingPriceClassName = useClassName('starting-price');
	const { secondaryInfo, startingPrice, videographyAvailable } =
		useContext(propsContext);
	const info = videographyAvailable ? [VIDEOGRAPHY_TEXT] : secondaryInfo;
	const hasInfo = info.length > 0;
	const showStartingPrice = shouldShowPricing && startingPrice !== null;
	const hasSecondaryInfo = info && info.length > 0;
	if (!showStartingPrice && !hasSecondaryInfo) {
		return null;
	}

	return (
		<div
			className={secondaryInfoContainerClassName}
			data-testid="secondary-info-container"
		>
			{showStartingPrice && (
				<Body2 bold>Starting at {formatCurrency(startingPrice)}</Body2>
			)}
			{hasInfo && (
				<div className={startingPriceClassName}>
					{info.map((text, index) => (
						<Item key={text} text={text} isFirst={index === 0} />
					))}
				</div>
			)}
		</div>
	);
};

export default SecondaryInfo;
