import { determineReferredMarketCode } from '@utils/index';
import { isFromSearchPage } from '@utils/regex';
import merge from 'deepmerge';
import AnalyticsConstants from '../../../constants/analytics';
import { VENDOR_REFERRED_MARKET_CODE } from '../../../constants/cookies';
import Vendor from '../../decorators/vendor';
import VendorAnalytics from '../../decorators/vendorAnalytics';
import { cookieGetItem } from '../../utils/cookie/consentManagementCookie';
import type {
	BuildCardClickEvent,
	BuildCarouselEvent,
	FilterTrackingData,
} from './types';

const calculateFiltersCount = (filters: Filters.CategoryFilters) =>
	Object.keys(filters).reduce(
		(filterCount, currentFilter) => filterCount + filters[currentFilter].length,
		0,
	);

const resolveSourceContent = (filters: Redux.Filters) => {
	const noFilters = calculateFiltersCount(filters.categoryFilters) === 0;

	if (noFilters) {
		return 'vendor default results';
	}

	return 'vendor filtered results';
};

const filterTrackingData: FilterTrackingData = (options, props) => {
	const { badges, currentPage, filters, index, sortType, isSemanticSearch } =
		options;
	const sourceContent = resolveSourceContent(filters);

	const trackingData: ReturnType<FilterTrackingData> = {
		badges: badges.map((badge) => badge.label),
		impressionPosition: index + 1,
		sourceChannel: 'internal',
		pageNumber: currentPage,
		sourceContent,
		sourceFormula: `sort by ${sortType}`,
		sourcePage: isSemanticSearch
			? AnalyticsConstants.SEMANTIC_RESULTS
			: AnalyticsConstants.CATEGORY_RESULTS,
	};

	if (props.sortVariation || props.sortVersion) {
		// add raw, undecorated sort type if variation and version are present
		trackingData.sort_type = props.sortType;

		// add version and variation sort meta data
		trackingData.sort_variation = props.sortVariation;
		trackingData.sort_version = props.sortVersion;
	}

	return trackingData;
};

export const buildCardClickEvent: BuildCardClickEvent = (options) => {
	const { vendor, index, props, badges, isSemanticSearch } = options;
	const { currentPage, filters, isReferred, marketCode, referrer, sortType } =
		props;
	const vendorAnalytics = new VendorAnalytics(new Vendor(vendor));
	const cookieMarketCode = cookieGetItem(VENDOR_REFERRED_MARKET_CODE);
	const isFromSearch = isFromSearchPage(referrer || '');

	const trackingData = filterTrackingData(
		{
			filters,
			sortType,
			index,
			currentPage,
			badges,
			isSemanticSearch,
		},
		props,
	);

	const vendorReferredMarketCode = determineReferredMarketCode({
		cookieMarketCode,
		isFromSearch,
		isReferred,
		marketCode,
	});

	const trackingProps = {
		...trackingData,
		vendorReferredMarketCode,
	};

	const properties = merge(vendorAnalytics.baseEventProps(), trackingProps);
	const event = AnalyticsConstants.CLICK_THROUGH_TO_VENDORS;

	return {
		event,
		properties,
	};
};

export const buildCarouselEvent: BuildCarouselEvent = (options) => {
	const { vendor, index, scrollDirection } = options;
	const vendorAnalytics = new VendorAnalytics(new Vendor(vendor));

	const properties = {
		...vendorAnalytics.baseEventProps(),
		sourcePage: 'category results',
		sourceContent: 'large vendor card',
		action: scrollDirection,
		mediaIndex: index,
		mediaType: 'PHOTO',
		item: vendor?.mediaSummary?.media?.[index].url,
	};

	return {
		event: AnalyticsConstants.VENDOR_PORTFOLIO_INTERACTION,
		properties,
	};
};
