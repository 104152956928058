import { compose } from '@xo-union/react-css-modules';
import type { Value } from '../types/context';
import NOOP from './noop';

const DEFAULT_PROPS_CONTEXT: Value = {
	badges: [],
	ctaLabel: 'Request Quote',
	dek: {},
	classes: compose({}),
	handleEvent: NOOP,
	handleEventTypes: ['click', 'loaded', 'visible'],
	hideRequestPricing: false,
	imageRatio: 2 / 3,
	isFavorited: false,
	id: '',
	lazyLoadImages: false,
	allMedia: [],
	maxImageCount: 5,
	name: '',
	onClickBadge: NOOP,
	onClickCard: NOOP,
	onClickCarousel: NOOP,
	onClickFavorite: NOOP,
	onClickRequestPricing: NOOP,
	preserveBrokenImages: false,
	reviewCount: 0,
	secondaryInfo: [],
	serviceArea: '',
	size: 'lg',
	starCount: 0,
	vendorLocation: '',
	url: '',
	isMobile: false,
	preload: false,
	quickResponder: false,
	videographyAvailable: false,
	startingPrice: null,
	isNew: false,
};

export default DEFAULT_PROPS_CONTEXT;
