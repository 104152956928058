import { useContext } from 'react';
import { mutableContext } from '../context';
import type { MutableContextKey, MutableContextValue } from '../types/context';

type UseMutableContextReturn<T extends MutableContextKey> = [
	MutableContextValue[T],
	(nextState: MutableContextValue[T]) => void,
];

type UseMutableContext = <T extends MutableContextKey>(
	key: T,
) => UseMutableContextReturn<T>;

const useMutableContext: UseMutableContext = (key) => {
	const state = useContext(mutableContext);
	const keyValue = state.value[key];

	const updateValue = <T extends MutableContextKey>(
		nextValue: MutableContextValue[T],
	) => {
		state.setValue({
			[key]: nextValue,
		});
	};

	return [keyValue, updateValue];
};

export default useMutableContext;
