import MembershipModalRedux, {
	type OpenModalType,
} from '@xo-union/tk-component-membership-modal-redux';
import { useAtomValue } from 'jotai';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { createPortal } from 'react-dom';
import analytics from '../../../constants/analytics';
import { isSemanticSearchCalledAtom } from '../../pages/VendorsSearch/containers/SemanticSearch/atoms';

export interface ModalRef {
	setOpenState(type: OpenModalType): void;
}

const Modal = forwardRef<ModalRef>((_props, ref) => {
	const [openState, setOpenState] = useState<OpenModalType | undefined>();
	const isSemanticSearchCalled = useAtomValue(isSemanticSearchCalledAtom);

	useImperativeHandle(ref, () => ({
		setOpenState,
	}));

	const element =
		typeof document !== 'undefined' && document?.getElementById('app');

	const onClickLogIn = () => {
		setOpenState('LOG_IN');
	};

	const onClickSignUp = () => {
		setOpenState('SIGN_UP');
	};

	const closeModal = () => {
		setOpenState(undefined);
	};

	if (!element) {
		return null;
	}

	const metadata = {
		userAction: 'favorites button',
		applicationName: 'marketplace',
	};

	const handleMetaData = () => {
		if (isSemanticSearchCalled) {
			return {
				...metadata,
				additionalAnalyticsProperties: {
					source_page: analytics.SEMANTIC_RESULTS,
				},
			};
		}
		return metadata;
	};

	const updatedMetadata = handleMetaData();

	return createPortal(
		<MembershipModalRedux
			metadata={updatedMetadata}
			openModalType={openState}
			onClickLogIn={onClickLogIn}
			onClickSignUp={onClickSignUp}
			onSignUpSuccess={closeModal}
			onLogInSuccess={closeModal}
			onClose={closeModal}
		/>,
		element,
	);
});

export default Modal;
