import { useContext } from 'react';
import { propsContext } from '../../context';
import type { TrackingEvent } from '../../types/events';
import * as utilities from './utilities';

type HandleEvent = <T extends TrackingEvent>(
	event: Omit<T, 'vendorId'>,
) => void;

type UseHandleEventReturn = [HandleEvent];

type UseHandleEvent = () => UseHandleEventReturn;

const useHandleEvent: UseHandleEvent = () => {
	const props = useContext(propsContext);
	const { id, handleEvent: reportHandleEvent, handleEventTypes } = props;

	const handleEvent: HandleEvent = (event) => {
		const payload = {
			...event,
			vendorId: id,
		} as TrackingEvent;

		utilities.handleClick({
			event: payload,
			props,
		});

		if (handleEventTypes.includes(event.type)) {
			reportHandleEvent(payload);
		}
	};

	return [handleEvent];
};

export default useHandleEvent;
