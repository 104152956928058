import Icon from '@xo-union/tk-component-icons';
import cx from 'classnames';
import React, { useContext, type FC } from 'react';
import { propsContext } from '../../context';
import { useClassName } from '../../hooks';

interface ReviewStarProps {
	showNewBadge: boolean;
}

const ReviewStar: FC<ReviewStarProps> = ({ showNewBadge }) => {
	const { reviewCount } = useContext(propsContext);
	const starsClassName = useClassName('stars');
	const starFilledClassName = useClassName('star-filled');
	const starClassName = useClassName('star');

	const filledClassName = cx(starsClassName, starFilledClassName);

	const emptyClassName = cx(starsClassName, starClassName);

	if (reviewCount > 0 || showNewBadge) {
		return (
			<Icon
				aria-hidden="true"
				name="star_filled"
				size="sm"
				className={filledClassName}
			/>
		);
	}
	return (
		<Icon aria-hidden="true" name="star" size="sm" className={emptyClassName} />
	);
};

export default ReviewStar;
