const roundReviewRating = (number: string | number) => {
	const parsedNum =
		typeof number === 'string' ? Number.parseFloat(number) : number;
	if (parsedNum === 0) {
		return 0;
	}
	const roundedNumber = Math.round(parsedNum * 10) / 10;
	return Number.parseFloat(roundedNumber.toFixed(1));
};

export default roundReviewRating;
