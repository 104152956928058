import { useAppSelector } from '@redux/hooks';
import React, { useEffect, useRef, type FC } from 'react';
import {
	useMedia,
	useMutableContext,
	useShouldShowPagination,
} from '../../../hooks';
import Dot from './dot';
import styles from './styles.scss';
import utilities from './utilities';

const Pagination: FC = () => {
	const [media] = useMedia();
	const dots = useRef(utilities.createIconArray(media));
	const shouldShowPagination = useShouldShowPagination();
	const [navigationIndex] = useMutableContext('navigationIndex');
	const isMobile = useAppSelector((state) => state.viewport.isMobile);

	useEffect(() => {
		dots.current = utilities.createIconArray(media);
	}, [media]);

	if (!shouldShowPagination) {
		return null;
	}

	const selectedDotIndex = utilities.getSelectedDotIndex(
		navigationIndex,
		media.length,
		isMobile,
	);

	return (
		<div role="tablist" className={styles.pagination}>
			{dots.current.map((id, i) => (
				<Dot
					key={id}
					index={i}
					dotCount={dots.current.length}
					selectedIndex={selectedDotIndex}
				/>
			))}
		</div>
	);
};

export default Pagination;
