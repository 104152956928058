import { betterImageAssignmentSelector } from '@redux/experiments/selectors/better-image';
import { useAppSelector } from '@redux/hooks';
import { Img, Picture, Source } from '@xo-union/tk-component-picture';
import React, { useContext, useMemo, useRef, FC } from 'react';
import { propsContext } from '../../../context';
import { useClassName, useHandleEvent, useMedia } from '../../../hooks';

const EXPERIMENT_IMAGE_HEIGHT = 490 * (640 / 730);

interface UseBetterImageExperimentValuesReturnValues {
	viewport: 'xs' | 'sm';
	height: number;
	width: number;
}

export const useBetterImageExperimentValues =
	(): UseBetterImageExperimentValuesReturnValues => {
		const isInbetterImageAssignment =
			useAppSelector((state) => betterImageAssignmentSelector(state)) ===
			'better-image';

		return useMemo(() => {
			return isInbetterImageAssignment
				? { viewport: 'xs', height: EXPERIMENT_IMAGE_HEIGHT, width: 640 }
				: { viewport: 'sm', height: 490, width: 730 };
		}, [isInbetterImageAssignment]);
	};

export interface ImageProps {
	image: Vendor.PhotoMedia;
	index: number;
}

const Image: FC<ImageProps> = (props) => {
	const { image, index } = props;
	const { lazyLoadImages, url, preload } = useContext(propsContext);
	const [, removeImage] = useMedia();
	const imageClassName = useClassName('image-slide');
	const imageRef = useRef<HTMLImageElement>(null);
	const [handleEvent] = useHandleEvent();
	const isLazy = index !== 0 || lazyLoadImages; // the default behavior should be lazy load all off screen images
	const { viewport, height, width } = useBetterImageExperimentValues();

	const onError = () => {
		removeImage(image);
	};

	const onClick = () => {
		handleEvent({
			name: 'card',
			type: 'click',
		});
	};

	// This is different from the id attribute that image has.
	const mediaApiId = image.url.match(/\/images\/(.+)$/)?.pop();

	if (!mediaApiId) {
		return null;
	}

	return (
		// eslint-disable-next-line react/jsx-no-target-blank
		<a onClick={onClick} href={url} rel="noopener" target="_blank">
			<Picture lazy={isLazy} id={mediaApiId} preload={index === 0 && preload}>
				<Source viewport={viewport} height={height} width={width} smartCrop />
				<Source viewport="md" height={315} width={470} smartCrop />
				<Source viewport="lg" height={210} width={315} smartCrop />
				<Source viewport="xl" height={280} width={420} smartCrop />
				<Source viewport="xxl" height={210} width={310} smartCrop />
				<Img
					className={imageClassName}
					elementProps={{
						style: {
							position: 'absolute',
							display: 'inherit',
						},
						ref: imageRef,
						onError,
					}}
					key={mediaApiId}
					// Height and width are defaults to satisfy lighthouse but we actually use the
					// Source values for the current breakpoint.
					height={210}
					width={310}
					src={image.url}
					smartCrop
				/>
			</Picture>
		</a>
	);
};

export default Image;
