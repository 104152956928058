import formatSavedVendorData from '@utils/formatSavedVendorData';
import { Logger } from '@utils/logger/logger';
import saveVendorUtils from '@utils/savedVendors';
import type { LocalSaves, SavedVendor } from './types';

export const fetchSaves = async (
	sessionToken: string | undefined,
	weddingId: string,
): Promise<LocalSaves> => {
	if (!weddingId || !sessionToken) {
		return {};
	}

	const response = await saveVendorUtils.get({
		sessionToken,
		weddingId,
	});

	const result: LocalSaves = {};
	if (response.errors) {
		const error = new Error(response.errors[0].detail);
		Logger.error({
			log_message: 'Error fetching saves',
			errorToLog: error,
			newRelic: true,
		});
		return {};
	}
	const saves: SavedVendor[] = response.saved_vendors;
	return saves.reduce((acc, save) => {
		const vendorId = save?.local_vendor?.id;
		if (vendorId && save?.id) {
			acc[vendorId] = save.id;
		}
		return acc;
	}, result);
};

export const createSave = async (
	vendor: Vendor.Raw,
	sessionToken: string | undefined,
	weddingId: string,
): Promise<LocalSaves | undefined> => {
	const formattedVendorData = formatSavedVendorData(vendor);

	const response = await saveVendorUtils.create({
		data: formattedVendorData,
		sessionToken,
		weddingId,
	});

	const save = response.saved_vendor || {};
	const { id } = save?.local_vendor || {};
	if (!id || !save.id) {
		return undefined;
	}

	return {
		vendorId: id,
		saveId: save.id,
	};
};

export const removeSave = async (
	saveId: string,
	sessionToken: string | undefined,
	weddingId: string,
): Promise<void> => {
	await saveVendorUtils.destroyByObjectId({
		savedVendorId: saveId,
		sessionToken: sessionToken || '',
		weddingId,
	});
};
