import styles from '../styles.scss';

export interface GetClassNameConfig {
	index: number;
	selectedIndex: number;
	mediaLength: number;
}

type GetPaginationClassName = (config: GetClassNameConfig) => string;

const MAX_DOTS_LENGTH = 5;

const isCurrentSelected = (
	selectedIndex: number,
	mediaLength: number,
): number => {
	if (mediaLength <= MAX_DOTS_LENGTH || selectedIndex <= 2) {
		return selectedIndex;
	}

	const left = mediaLength - selectedIndex - 1;
	if (left <= 1) {
		return MAX_DOTS_LENGTH - left + 1;
	}

	return 2;
};

const isFirstSmall = (selectedIndex: number, mediaLength: number): boolean =>
	mediaLength > MAX_DOTS_LENGTH && selectedIndex > 2;

const isLastSmall = (selectedIndex: number, mediaLength: number): boolean =>
	mediaLength > MAX_DOTS_LENGTH &&
	selectedIndex < mediaLength - MAX_DOTS_LENGTH + 1;

const getClassName: GetPaginationClassName = (config) => {
	const { index, mediaLength, selectedIndex } = config;

	const itemsLength = Math.min(mediaLength, MAX_DOTS_LENGTH);
	const currentIsSelected = isCurrentSelected(selectedIndex, mediaLength);

	// If the current index is the selected index, return large filled circle
	if (index === currentIsSelected) {
		return styles.paginationCircleSelected;
	}

	// If the current index is outside of the range of circles, return small gray circle
	const firstIsSmall = isFirstSmall(selectedIndex, mediaLength);
	const lastIsSmall = isLastSmall(selectedIndex, mediaLength);
	const isNotFirst = index === 0 && firstIsSmall;
	const isNotLast = index === itemsLength - 1 && lastIsSmall;

	if (isNotFirst || isNotLast) {
		return styles.paginationCircleUnselectedSmall;
	}

	// Return medium gray circle
	return styles.paginationCircleUnselected;
};

export default getClassName;
