import { useTrackOpenFilterMenu } from '@hooks/use-track-open-filter-menu';
import React, {
	type FunctionComponent,
	useEffect,
	useRef,
	useState,
} from 'react';
import { afterNextPaint } from '../../../../helpers/after-next-paint';
import useClickListenerOnPillDropdown from '../../../pages/VendorsSearch/containers/FilterPills/hooks/useClickListenerOnPillDropdown';
import Dropdown from './Dropdown';
import Pill from './Pill';

interface Option {
	name: string;
	value: string;
	subtext?: string;
}
export interface Props {
	appliedOptions?: string[];
	isMobile: boolean;
	onClearOptions?: () => void;
	onDropdownToggle?: (isOpen: boolean) => void;
	onPillClick?: () => void;
	onSave?: (selectedOptions: string[]) => void;
	onSelectionChange?: (selectedOptions: string[]) => void;
	options: Option[];
	pillLabel: string;
	singleSelect?: boolean;
	defaultOptions?: string[];
}
const buildPillLabel = (
	pillLabel: string,
	selectedOptionsLength: number,
): string =>
	selectedOptionsLength > 1
		? `${pillLabel} (${selectedOptionsLength})`
		: pillLabel;
const selectionsHaveChanged = (
	appliedOptions: string[],
	selectedOptions: string[],
) =>
	appliedOptions.length !== selectedOptions.length ||
	!appliedOptions.every((option) => selectedOptions.includes(option));
// eslint-disable-next-line @typescript-eslint/no-empty-function
const baseFunction = () => {};
export const PillWithDropdown: FunctionComponent<Props> = ({
	appliedOptions = [],
	isMobile,
	onClearOptions,
	onDropdownToggle = baseFunction,
	onPillClick = baseFunction,
	onSave,
	onSelectionChange,
	options,
	pillLabel,
	singleSelect,
	defaultOptions,
}) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const [selectedOptions, setSelectedOptions] = useState(appliedOptions || []);
	const [pillSelected, setPillSelected] = useState(
		selectedOptions.length !== 0,
	);
	const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
	const closeDropdown = () => {
		setDropdownOpen(false);
		onDropdownToggle(false);
	};
	useClickListenerOnPillDropdown({
		closeDropdown,
		containerRef,
		dropdownOpen,
		isMobile,
	});
	const trackOpenFilterMenu = useTrackOpenFilterMenu();

	useEffect(() => {
		if (selectionsHaveChanged(appliedOptions, selectedOptions)) {
			setSelectedOptions(appliedOptions);
			setPillSelected(appliedOptions.length !== 0);
		}
	}, [appliedOptions, selectedOptions]);
	const onDropdownSave = (updatedOptions: string[]) => {
		afterNextPaint(() => {
			setSelectedOptions(updatedOptions);
			setPillSelected(updatedOptions.length !== 0);
			closeDropdown();
			if (onSave) {
				onSave(updatedOptions);
			}
		});
	};
	const onClick = () => {
		const nextDropdownToggle = !dropdownOpen;

		afterNextPaint(() => {
			if (nextDropdownToggle) {
				trackOpenFilterMenu(pillLabel);
			}
			onDropdownToggle(nextDropdownToggle);
			setDropdownOpen(nextDropdownToggle);
			onPillClick();
		});
	};
	return (
		<div ref={containerRef}>
			<Pill
				label={buildPillLabel(pillLabel, selectedOptions.length)}
				onClick={onClick}
				selected={pillSelected || dropdownOpen}
			/>
			{dropdownOpen && (
				<Dropdown
					isModalOpen={dropdownOpen}
					modalHeader={pillLabel}
					onClear={onClearOptions}
					onClose={closeDropdown}
					onSave={onDropdownSave}
					onSelectionChange={onSelectionChange}
					options={options}
					selectedOptions={selectedOptions}
					singleSelect={singleSelect}
					defaultOptions={defaultOptions}
				/>
			)}
		</div>
	);
};
export default PillWithDropdown;
