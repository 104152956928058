import BlankButton from '@xo-union/component-blank-button';
import React, { type FC } from 'react';
import utilities from './utilities';

interface DotProps {
	index: number;
	selectedIndex: number;
	dotCount: number;
}

const Dot: FC<DotProps> = (props) => {
	const { selectedIndex, index, dotCount } = props;

	const className = utilities.getClassName({
		index,
		selectedIndex,
		mediaLength: dotCount,
	});

	return (
		<BlankButton
			aria-label={`Navigate to image ${index + 1}`}
			role="tab"
			className={className}
		/>
	);
};

export default Dot;
