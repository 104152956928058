import { DEFAULT_ALL_RADIO_OPTION_ID } from '@redux/search';
import { Button } from '@xo-union/tk-component-buttons';
import React, { useEffect, useState } from 'react';
import type { FCWithChildren } from 'types/react-extended';

export interface Props {
	disabled: boolean;
	isMobilePill?: boolean;
	isSortFilter?: boolean;
	onSave: (selectedOptions: string[]) => void;
	stagedOptions: string[];
}

const SaveButton: FCWithChildren<Props> = ({
	children,
	disabled,
	isMobilePill,
	isSortFilter,
	onSave,
	stagedOptions,
}) => {
	const [appliedOptions, setAppliedOptions] = useState(stagedOptions || []);
	const [disableSave, setDisableSave] = useState(
		disabled || appliedOptions.length === 0,
	);

	const stagedNonDefaultOptions = stagedOptions.filter(
		(o) => o !== DEFAULT_ALL_RADIO_OPTION_ID,
	);
	const stagedOptionsHaveChanged =
		appliedOptions.length !== stagedNonDefaultOptions.length ||
		!appliedOptions.every((option) => stagedNonDefaultOptions.includes(option));
	useEffect(() => {
		if (isSortFilter) {
			setDisableSave(false);
		} else {
			setDisableSave(disabled || !stagedOptionsHaveChanged);
		}
	}, [disabled, isSortFilter, stagedOptionsHaveChanged]);

	const saveSelections = () => {
		setDisableSave(true);
		setAppliedOptions(stagedOptions);
		onSave(stagedOptions);
	};

	return (
		<Button
			block
			color={isMobilePill || isSortFilter ? 'primary' : 'tertiary-alternative'}
			size="md"
			disabled={disableSave}
			onClick={saveSelections}
			type="button"
		>
			{children}
		</Button>
	);
};

export default SaveButton;
