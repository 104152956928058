import NavigableModal, {
	MODAL_TYPES,
} from '@components/NavigableModal/NavigableModal';
import { getStagedFilteredResultsCtaText } from '@components/shared/PillWithDropdown/Dropdown/containers/Mobile/utils';
import { useAppSelector } from '@redux/hooks';
import { IconButton } from '@xo-union/tk-component-icons';
import { DisplayButton } from '@xo-union/tk-ui-links';
import { ModalHeader, ModalPortal } from '@xo-union/ui-accessibility';
import React, { type FC } from 'react';
import type { DropdownChildProps } from '../../DropdownProps';
import Dropdown from '../../components';
import SaveButton from '../../components/SaveButton';
import Styles from './styles.scss';

export interface Props extends DropdownChildProps {
	modalHeader: string;
	isModalOpen: boolean;
	onClose: () => void;
	isSortFilter?: boolean;
}

interface HeaderProps {
	clearSelections: (e: React.MouseEvent) => void;
	onClose: () => void;
	stagedCount: number;
	titleText: string;
	isSortFilter?: boolean;
}
const Header: FC<HeaderProps> = ({
	clearSelections,
	onClose,
	stagedCount,
	titleText,
	isSortFilter,
}) => (
	<div className={Styles.modalHeaderContainer}>
		{stagedCount > 0 && !isSortFilter && (
			<DisplayButton
				onClick={clearSelections}
				className={Styles.clearAllLink}
				type="button"
			>
				Clear All
			</DisplayButton>
		)}
		<ModalHeader className={Styles.modalHeader}>{titleText}</ModalHeader>
		<IconButton
			name="close"
			block
			size="md"
			onClick={onClose}
			aria-label="close"
		/>
	</div>
);

const MobileDropdown: FC<Props> = (props) => {
	const {
		modalHeader,
		isModalOpen,
		onClose,
		onSave,
		stagedOptions,
		isSortFilter,
		...dropdownProps
	} = props;
	const resultsCount = useAppSelector(
		(state) => state.search.filterPills.stagedResultsCount,
	);
	const stagedCount = stagedOptions.length;
	const titleText =
		stagedCount === 0 || isSortFilter
			? modalHeader
			: `${modalHeader} (${stagedCount})`;
	const clearSelections = (e: React.MouseEvent) => {
		e.stopPropagation();
		props.setStagedOptions([]);
		if (props.onClear) {
			props.onClear();
		}
	};

	const resultsCTA = getStagedFilteredResultsCtaText(resultsCount);

	return (
		<ModalPortal>
			<NavigableModal
				hash="mobile-dropdown"
				isModalOpen={isModalOpen}
				closeCallback={onClose}
				type={MODAL_TYPES.CONTAINER}
				className={Styles.modalContainer}
			>
				<Header
					clearSelections={clearSelections}
					onClose={onClose}
					stagedCount={stagedCount}
					titleText={titleText}
					isSortFilter={isSortFilter}
				/>
				<div className={Styles.dropdownContainer}>
					<Dropdown stagedOptions={stagedOptions} {...dropdownProps} />
				</div>
				<div className={Styles.seeResultsContainer}>
					<SaveButton
						disabled={resultsCount === 0}
						isMobilePill
						isSortFilter={isSortFilter}
						onSave={onSave}
						stagedOptions={stagedOptions}
					>
						{isSortFilter ? 'Save' : resultsCTA}
					</SaveButton>
				</div>
			</NavigableModal>
		</ModalPortal>
	);
};

export default MobileDropdown;
