import Geo from '@xo-union/sdk-geo';
import type { Category } from 'types/redux';
import { truncate } from '../../../utils';
import urlSafe, {
	INTERNAL_REFERRER_SORT_PARAM,
} from '../../../utils/urlHelpers';

export const searchGeo = async (query: string): Promise<Union.Location[]> => {
	const geo = new Geo();
	const response = await geo.locationsByCity(query);
	return response.body.locations;
};

export const getRandomKey = () => Math.random();

export const buildAddress = (address: Vendor.Address) => {
	let location = '';
	if (address.address1) {
		location = `${location}${address.address1},`;
	}
	if (address.city) {
		location = `${location} ${address.city},`;
	}
	if (address.state) {
		location = `${location} ${address.state}`;
	}
	return location;
};

export const getElement = (
	optionRef: React.MutableRefObject<HTMLSpanElement | null>,
): HTMLElement | null => {
	const children = optionRef.current?.querySelectorAll('li');
	return children ? children[0] : null;
};

export const getAvailableWidth = (
	optionRef: React.MutableRefObject<HTMLSpanElement | null>,
) => {
	const client = optionRef.current?.getBoundingClientRect();
	const width = client?.width || 1000;
	return width - 48;
};

export const setNameEllipses = (
	categories: Redux.Categories,
	availableWidth: number,
	element: HTMLElement | null,
): Redux.Categories => {
	return categories.map((category) => ({
		...category,
		name: element
			? truncate(category.name, element, availableWidth)
			: category.name,
	}));
};

export const getDefaults = (location: Union.Location) => ({
	locationHash: {
		city: location.city,
		stateCode: location.stateCode,
	},
	cityState: `${location.city}, ${location.stateCode}`,
});

export const buildSearchRef = (
	categories: Redux.Categories,
	code: string,
	locationHash: Union.Location | null,
) => {
	const category = categories.find((cat) => cat.code === code);
	const isFormValid =
		code && locationHash && locationHash.city && locationHash.stateCode;

	if (!category || !isFormValid) {
		return '';
	}
	return `/marketplace/${category.slug}-${urlSafe(locationHash.city)}-${urlSafe(
		locationHash.stateCode,
	)}?${INTERNAL_REFERRER_SORT_PARAM}`;
};

export const getPromptTerm = (category: Category) => {
	switch (category.code) {
		case 'VID':
			return 'Videographer';
		case 'BDS':
			return 'Wedding Band';
		case 'CAT':
			return 'Caterer';
		case 'BAR':
		case 'DNC':
		case 'DEC':
		case 'FAV':
		case 'INV':
		case 'SPV':
		case 'RNT':
		case 'TRA':
			return 'Vendor';
		default:
			return category.singular.term;
	}
};
