import { Body2 } from '@xo-union/tk-ui-typography';
import { ScreenReaderOnly } from '@xo-union/ui-accessibility';
import React, { Fragment, FC } from 'react';
import { useClassName } from '../../hooks';

interface ItemProps {
	isFirst?: boolean;
	srOnly?: string;
	text?: string;
}

const Item: FC<ItemProps> = (props) => {
	const { isFirst, text, srOnly } = props;
	const secondaryInfoClassName = useClassName('secondary-info');
	const secondaryInfoBulletClassName = useClassName('secondary-info-bullet');

	if (!text) {
		return null;
	}

	return (
		<Fragment>
			{!isFirst && (
				<Body2 className={secondaryInfoBulletClassName} aria-hidden="true">
					•
				</Body2>
			)}
			<Body2
				aria-hidden={srOnly ? 'true' : 'false'}
				key={text}
				className={secondaryInfoClassName}
			>
				{text}
			</Body2>
			{srOnly && <ScreenReaderOnly>{srOnly}</ScreenReaderOnly>}
		</Fragment>
	);
};

export default Item;
