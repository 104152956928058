import { ScreenReaderOnly } from '@xo-union/ui-accessibility';
import React, { Fragment, FC } from 'react';
import { getFullState } from './utilities';

interface StateProps {
	state?: string;
}

const State: FC<StateProps> = (props) => {
	const { state } = props;

	if (!state) {
		return null;
	}

	const fullState = getFullState(state);

	if (!fullState) {
		return <Fragment>, {state}</Fragment>;
	}

	return (
		<Fragment>
			<span aria-hidden={fullState ? 'true' : 'false'}>, {state}</span>
			<ScreenReaderOnly>{fullState}</ScreenReaderOnly>
		</Fragment>
	);
};

export default State;
