import { semanticSearchApiUrl } from '@settings';
import type { SemanticSearchRequest, SemanticSearchResponse } from './types';

export const semanticSearch = async (requestBody: SemanticSearchRequest) => {
	const headers = new Headers();

	headers.append('Content-Type', 'application/json');

	const response = await fetch(semanticSearchApiUrl, {
		method: 'POST',
		headers,
		body: JSON.stringify(requestBody),
	});

	const data = await response.json();

	return data as SemanticSearchResponse;
};

export const semanticSearchApiV2 = async (requestTerm = '') => {
	await new Promise((resolve) => setTimeout(resolve, 4000));
	return {
		storefronts: [
			{
				id: '9214cf3d-10c7-4a1d-a7bb-a107f9d9a24d',
				similarity_score: 0.6997095346450806,
				name: 'Bella Terra Venue',
				address: '10 West Ave',
				city: 'Gunter',
				state: 'TX',
				photo: {
					id: 'd0753cf2-63ee-47af-835a-15eeabcb25f3',
					height: 200,
					width: 200,
					url: 'https://qa-media-api.xogrp.com/images/c6460c14-1696-4f85-9dc7-6174ee264531',
				},
			},
			{
				id: '2f9035eb-97a8-4083-92a8-a85801040f1a',
				similarity_score: 0.6996052265167236,
				name: 'The Establishment Barn',
				address: '8081 County Road 344',
				city: 'Terrell',
				state: 'TX',
				photo: {
					id: '35d60785-8594-4306-ae72-46e86ba1a0e9',
					height: 200,
					width: 200,
					url: 'https://qa-media-api.xogrp.com/images/27df25df-da70-4f23-941d-495222b61233',
				},
			},
		],
		searchTerm: 'rooftop loft',
		semanticSearchResults: [
			{
				facet: 'Country Club',
				similarity_score: 0.35467,
			},
			{
				facet: 'Barn',
				similarity_score: 0.25447,
			},
			{
				term: 'beer',
				similarity_score: 0.345,
			},
			{
				term: 'rooftop pool',
				similarity_score: 0.242,
			},
		],
		response_id: '06ad6319-dbee-441c-975e-bea2a3cb9577',
	};
};
